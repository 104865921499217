<template>
    <div class="body">
        <!--导航栏--->
        <section class="top">
            <van-nav-bar title="填写物流" left-arrow :fixed='isTop' @click-left="$router.back(-1)" />
        </section>
        <!-- 物流商品信息 -->
        <section class="shopInfo">
            <div class="goodsInfo">
                <div class="goodsList">
                    <van-image width="5.5rem" height="6rem" fit="fill" :src="orderInfo.picUrl" alt="没商品图片" />
                    <div class='goodsText'>
                        <div>
                            <p>{{ orderInfo.name }}</p>
                        </div>
                        <div class="size fontColor">
                            <span>规格：{{ specification }}</span>
                            <span>x{{ quantity }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- 退回地址 -->
        <section class="logisticsInfo">
            <div>退回地址</div>
            <div>
                <p><span>小小徐</span><span>18552439022</span></p>
                <p>安徽省合肥市包河区万达广场旁海大小区</p>
            </div>
            <div class="waybillNum">
                <van-cell-group>
                    <van-field v-model="waybillNum" label="运单号码" placeholder="请填写运单号码" />
                </van-cell-group>
            </div>
            <div class="logisticsCompany">
                <van-field v-model="fieldValue" is-link readonly label="快递公司" placeholder="请选择" @click="show = true" />
                <van-popup v-model="show" round position="bottom">
                    <van-cascader v-model="cascaderValue" active-color="#FB2B53" title="请选择快递公司" :options="options"
                        @close="show = false" @finish="onFinish" />
                </van-popup>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data() {
        return {

            isTop: true,
            orderInfo: {},//商品信息
            quantity: '',//数量
            specification: '',//规格
            waybillNum: '',//运单号码
            show: false,
            fieldValue: '',
            cascaderValue: '',
            // 选项列表，children 代表子选项，支持多级嵌套
            options: [
                {
                    text: '顺丰快递',
                },
                {
                    text: '京东快递',
                },
                {
                    text: '德邦快递',
                },
                {
                    text: '中通快递',
                },
                {
                    text: '申通快递',
                },
            ],
        }
    },
    created() {
        this.orderInfo = this.$route.query.orderInfo
        this.quantity = this.$route.query.quantity
        this.specification = this.$route.query.specification
    },
    mounted() {
        let screenWidth = document.documentElement.getBoundingClientRect().width;
        document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
    methods: {
        onFinish({ selectedOptions }) {
            this.show = false;
            console.log(selectedOptions);
            this.fieldValue = selectedOptions.map((option) => option.text).join('');
        },
    }
}
</script>

<style lang="less" scoped>
.body {
    width: 100%;
    height: 100%;
    background: #F6F6F6;

    .van-step--vertical .van-step__circle-container {
        z-index: 0;
    }

    section {
        padding: 0.4rem 1rem;
        margin-bottom: 1rem;
        background: #fff;
    }

    .top {
        height: 6%;
    }

    .shopInfo {
        .goodsInfo {
            display: flex;
            flex-flow: column nowrap;
            padding-top: 1rem;

            .goodsList {
                display: flex;
                flex-flow: row nowrap;

                .goodsText {
                    margin-left: 1.2rem;
                    display: flex;
                    flex: 1;
                    flex-flow: column nowrap;
                    justify-content: space-between;

                    p {
                        width: 15rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .size {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}
</style>